
import { useState, useEffect } from "react";
import React from 'react';

import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
//import { Quill } from "react-quill";
//import "react-quill/dist/quill.snow.css";

//import 'quill/dist/quill.snow.css'; // Add css for snow theme
//import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

import { isValidToken } from '../../Javascript_Functions_to_export/Utils';

import axios from "axios";
import './PostJob.css';

import {
    getCountries, 
    getEmploymentTypes, 
    getWorkPlaces, 
    getGenders, 
    getJobCategories } from '../../Javascript_Functions_to_export/Utils';

import { useNavigate, useLocation } from "react-router-dom";

import { useToast } from '../../CustomeHooks/useToast';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';

import Cookie from 'js-cookie';

const PostJob = ({ setLogoFn }) => {

    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const [currentUser, setCurrentUser] = useState(null);
    
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_URL}/touch_session`)
    //     .then(res => {
    //         console.log("sessionEnded=", res.data.sessionEnded, "expire=", res.data.expire);
    //         if(res.data.sessionEnded && res.data.sessionEnded === true) {
    //             console.log("should logout");
    //             alert("Session has expired.")
    //             if(localStorag.getItem('Logo'))
    //                 localStorag.removeItem('Logo');
    //             window.location.href = process.env.REACT_APP_ORIGI + "/login";
    //         }
    //     });
    // }, []);


    useEffect(() => {
        
        //if(document.cookie) {
        if(Cookie.get('token')) {
            //console.log("token been changed -!-");
            setCurrentUser(getUserFromCookie(Cookie.get('token'))); 
        }
    }, [Cookie.get('token')]);

    useEffect(() => {
       
        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        window.scrollTo(0, 0);

    }, []);

    useEffect(() => {
        
        // initialize all Dummy values (Just like 'Othman Fayed' in <<WPF>>)
        
        setCountries(getCountries());
        setCategories(getJobCategories());
        setEmploymentTypes(getEmploymentTypes()); 
        setWorkPlaces(getWorkPlaces());
        setGenders(getGenders());
    
        //console.log("location=", location);

        // user navigate to this page manually (using url)
        //if(!location.state && !document.cookie) {
        //    //toast('warning', 'Please login first !');
        //    navigate('/login');
        //}
        // else {
        //     // user already was in postJob page, and clicks "Logout"
        //     if(location && 
        //        location.state && 
        //        location.state.LastVisitedPage === "/postjob" && 
        //        !document.cookie) {
        //         navigate('/login');
        //     }
        // }

        // old code

        // If userNot logged In => Navigate to "LoginIn" page
        // if(!document.cookie) {
        //     toast('update', 'Please login first !');
        //     navigate("/login");
        // }
        // else {
            
        // }

    }, [currentUser]);


    const [isSending, setIsSending] = useState(false);

    const [country, setCountry] = useState(0);
    const [category, setCategory] = useState(10);    // IT|Sales|Teaching|Other.
    const [employmentType, setEmploymentType] = useState(10);
    const [workplace, setWorkplace] = useState(10);
    
    const [jobTitle, setJobTitle] = useState('');   // web developer|It support|front end| ... 
    const [jobTitleError, setJobTitleError] = useState('');

    const _setJobTitle = (e) => {
        if(e.target.value.trim().length === 0)
            setJobTitleError('Job title is required');
        else
            setJobTitleError('');

        setJobTitle(e.target.value);
    }

    const [gender, setGender] = useState(10);
    const [salary, setSalary] = useState('');
    const [emailSubject, setEmailSubject] = useState('');

    const [description, setDescription] = useState(''); // it will give you the HTML automatically

    const [countries, setCountries] = useState();
    const [categories, setCategories] = useState();
    const [employmentTypes, setEmploymentTypes] = useState();
    const [workPlaces, setWorkPlaces] = useState();
    const [genders, setGenders] = useState();


    axios.defaults.withCredentials = true;

    //const handleSubmit = (e) => {
    const PostJob = () => {
        //e.preventDefault();

        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');
        
        // User did not logged in yet.
        if(!_token && !sessionStorage.getItem('Logo')) {
            toast('update', 'Please login as an Employer !');
            return;
        }

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        // If user closes the tab || 
        //         closes the browser ||
        //         or open a new tab
        // before the session expired
        // if(_token && !sessionStorage.getItem('Logo')) {
        //     axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        //     .then(res => {
        //         if(sessionStorage.getItem('Logo'))
        //             sessionStorage.removeItem('Logo');

        //         window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     })
        //     .catch(err => {
        //         toast('error', err.response.data.message);
        //     })
        // }

        if(!currentUser) {
            toast('error', 'Unexpected error !');
            return;
        }

        // this block of code has been moved to the server side, in order to don't modify the cookie. 
        // // If an "Employer" tries to post job before it enters its "Email"
        // if(currentUser && currentUser.AccountType === 1 &&
        //     (!currentUser.CompanyEmail || currentUser.CompanyEmail.trim() === 'undefined' || currentUser.CompanyEmail.trim() === "null")) {
        //     //console.log("sending", currentUser);
        //     toast('warning', 'Please enter your email in profile section !');
        //     return;
        // }

        // If a candidate tries to post job
        if(currentUser && currentUser.AccountType === 0) {
            toast('update', 'Sorry, candidates cannot post a job');
            return;
            //navigate('/search');
        }

        if(currentUser && !currentUser.CompanyEmail) {
            toast('warning', "Please open your 'Profile' and enter 'Company Email' !");
            return;
        }

        if(country <= 0) {
            toast('warning', 'Country is required !');
            return;
        }

        if(!jobTitle || jobTitle.trim() == '') {
            toast('warning', 'Job Title is required !');
            return;
        }

        //window.location.href = "http://localhost/initiate.php";


        //return;
        //debugger;
        var newJob = { 
            //Name: companyName,   // he inserted the null, SH*****t.
            //Name: 'El-Haceb S.A.L',
            //Confidential : confidential === true ? 1: 0,
            //Confidential : 0,
            //CompanyUsername: email,
            //CompanyUsername: 'CompanyUsername',  // the unique field 
            CompanyUsername: currentUser.Username,  // the unique field  
            Country: country,
            JobCategory: category,
            EmploymentType: employmentType, 
            Workplace: workplace, 
            JobTitle: jobTitle, 
            Gender: gender, // if string is sent, it will save 0 in DB.
            Salary: salary,
            EmailSubject: emailSubject,
            JobDescription: description,
            
            //Location: 'Lebanon',
            //EmailBody: emailBody,
            //EmailSubject: emailSubject,
            //CompanyProfile: companyProfile,
            //EmailBody: 'email Body',
            //EmailSubject: 'email Subject',
            //CompanyProfile: 'company Profile',
        };
        
        setIsSending(true);
        //return;
        
        // the below block of code, wasted ~ 3 days of my time. once I used axios, I can return data as I want.
        /*fetch('http://localhost:808x/post_a_job', {
            method: "POST",
            mode: "cors",   //"no-cors",
            headers: {
                'Content-Type': 'application/json',
        },
            body: JSON.stringify(newJob)  // it was newAirplane in "AviMall.com"
        })*/
        
        //debugger;

        //axios.post('http://localhost:808x/post_a_job', newJob)
        axios.post(`${process.env.REACT_APP_URL}/post_a_job`, newJob)
        .then((res) => {
        
            //if(!res.ok) {
            // if(res.response.status === 401) {
            //         navigate("/login");
            //         throw Error("er"); 
            // }
            if(res.data.status === 'success') 
                toast("success", res.data.message);
            else //(res.data.status === 'Error') {
                toast("error", res.data.message);
            

            // ____________________________Old code (with fetch)____________________________
            // if response is not ok, throw an Error, to catch it in the "catch block", 
            // instead of using alert message.
            //if(!res.ok) {
                //console.log('res = ', res);
                
                //throw Error("bad response")
                //if(res.status === 500) {
                    //toast('there was an error in posting job.');
                    //console.log('res.data = ', res.data);
                    //throw new Error('there was an error in posting job.');
                //}
                //else {
                    //toast('company name is empty = ', res);
                    //console.log('res', res);
                    //throw Error('company name is empty');
                //}
            //}
            //else { //if(res.status === 200)
                //toast("Your job has been posted successfuly,\nThanks for using hiremeworld");
                
                // The trick was in the below line, we should return from "the first then".
                //return res.json();
            //}
            // ____________________________Old code (with fetch)____________________________

        })
        // no need for this second then, when using axios 
        // .then(data => {
        //     console.log('data = ', data.message);
        // })
        .catch(err => {
            console.log("4error = ", err);

            // if(err.response.status === 401) {
            //     toast("err", err.response.data.message);
            // }
            // if(err.response.status === 405) {
            //     toast("err", err.response.data.message);
            //     navigate("/login", { state: { LastVisitedPage: "postjob" } });
            // }
            //console.log("err = ", e);
        })
        .finally(() => {
            setIsSending(false);
        })
    }
    

    // link reference : https://quilljs.com/docs/modules/toolbar
    const _modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],  // worked at the buttom ReactQuill
            //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            
            //[{ 'font': [] }],

            ['bold', 'italic', 'underline', 'strike'], // 4 buttons 
            [{ 'list': 'ordered'}, { 'list': 'bullet' }], // worked at the buttom ReactQuill
            [{ 'script': 'sub'}, { 'script': 'super' }],      // worked at the buttom ReactQuill
            [{ 'color': [] }, { 'background': [] }],   // worked at the buttom ReactQuill
            
            
            //['link', 'image'],    // doesn't work correctly with HTML format  (it adds http://localhost ...)
            //[{ 'align': [] }],    // doesn't work with HTML format
            
            //[{ 'size': ['huge', 'large', false, 'small'] }],  // worked at the buttom ReactQuill
            //[{ 'header': 1 }, { 'header': 2 }]],   // I think prevent spaces
        ]
        //toolbar: '#toolbar' // gives => Cannot read properties of null (reading 'classList')
        //magicUrl: true
    };

    const php = () => {
        try {

            window.location.href = "http://localhost/initiate.php";
            return;
            console.log("paying ...");
            const response = fetch('http://localhost/PHPserver/server.php');
            const data = response.json();
            //setMessage(data.message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return(
        <div className="job-post-container every-page-container">
                {/* <form onSubmit={ (e) => handleSubmit(e) }> */}
                <form>
                    <div className='page-container'>
                        
                        <div className='title-section'>
                            <div className='title'>
                                <h2>Post a Job</h2>
                            </div>
                            <div className='path'>
                                <div className='home'>Log In</div>
                                <div className='circle'></div>
                                <div className='change-password'>Post</div>
                            </div>
                        </div>

                        <div className='fields-section'>
                            <div className='row-wrap'>

                                {/* <div className='payments-info'>
                                    Note: Once you post a job successfully, we will sent you an email, 
                                          to complete the process in order to your job will be shown in search. <br/>
                                          for more info please do not hesitate to contact us. 
                                </div> */}
{
    
}
                                <div className='pay-info-and-post field-comp' id='div-apply' >
                                    { Cookie.get('token') && 
                                        <span className="pay-info"
                                              style={{ visibility: 
                                                Cookie.get('token') && 
                                                currentUser && 
                                                !currentUser.CompanyEmail &&
                                                currentUser.AccountType === 1 ? 
                                                'visible' : 
                                                'hidden' }} >
                                            <u>Note</u>: 
                                                Before posting any job,&nbsp;
                                                <font color="red">
                                                please click the "User icon" on the top right corner, and choose "Profile"</font>, 
                                                then enter the 'Company Email'.<br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                The posted jobs will still inactive, therefore will not appear in search results, 
                                                until we activate them.<br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                For more info please do not hesitate to contact us. 
                                        </span>
                                    }
                                    { Cookie.get('token') && 
                                        <span className="pay-info-mobile"
                                            style={{ visibility: 
                                                Cookie.get('token') && 
                                                currentUser && 
                                                !currentUser.CompanyEmail &&
                                                currentUser.AccountType === 1 ? 
                                                'visible' : 
                                                'hidden' }} >
                                            <u>Note</u>: 
                                                Before posting any job,&nbsp;
                                                <font color="red">
                                                please click the "User icon" on the top right corner, and choose "Profile"</font>, 
                                                then enter the 'Company Email'.<br />
                                                The posted jobs will still inactive, therefore will not appear in search results, 
                                                until we activate them.<br />
                                                For more info please do not hesitate to contact us. 
                                        </span>
                                    }
                                    
                                    {/* { 
                                        !isSending ? */}
                                        <input name='applyjob'
                                            //    type='submit'
                                               style={{ marginLeft: 'auto' }}
                                               type='button'
                                               onClick={ PostJob }
                                               className={`job-btn-itself ${isSending ? 'disabled' : ''}`}
                                               value={`${isSending ? 'Posting' : 'Post'}`} disabled={ isSending }
                                               />
                                    {/* //     :
                                    //     <div name='btn-neutral' className="disabled">
                                    //         Posting
                                    //     </div>
                                        
                                    // } */}
                                </div>

                                <div className='country-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Country <span style={{ color: 'red' }}>*</span>
                                    </div> 
                                    <div className='field-container'>        
                                        <select name="country" 
                                                onChange={(e) => setCountry(e.target.value) } 
                                                value={ country }
                                                className='input-itself'>
                                            {
                                                countries && 
                                                Array.isArray(countries) &&
                                                countries.map((country, index) => {
                                                    return(
                                                        <option key={index} 
                                                                value={ country.Id }>
                                                                    { country.Name }
                                                        </option>                                        
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='category-section field-comp'>
                                    <div className='comp-title'>
                                        Category
                                    </div> 
                                    <div className='field-container'>        
                                        <select name="category" 
                                                onChange={(e) => setCategory(e.target.value) } 
                                                value={ category }
                                                className='input-itself'>
                                            {
                                                categories && 
                                                Array.isArray(categories) &&
                                                categories.map((category, index) => {
                                                    return(
                                                        <option key={index} 
                                                                value={ category.Id }>
                                                                    { category.Name }
                                                        </option>                                        
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className='employmenttpye-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Employment Type
                                    </div> 
                                    <div className='field-container'>        
                                        <select onChange={(e) => setEmploymentType(e.target.value) } 
                                                value={ employmentType }
                                                name="employmentType" 
                                                className='input-itself'>
                                            {
                                                employmentTypes && 
                                                Array.isArray(employmentTypes) &&
                                                employmentTypes.map((type, index) => {
                                                    return(
                                                        <option key={index} 
                                                                value={ type.Id }>
                                                                    { type.Name }
                                                        </option>                                        
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='workplace-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Workplace
                                    </div> 
                                    <div className='field-container'>
                                        <select onChange={(e) => setWorkplace(e.target.value) } 
                                                value={ workplace }
                                                name="workplace" 
                                                className='input-itself'>
                                            {
                                                workPlaces && 
                                                Array.isArray(workPlaces) &&
                                                workPlaces.map((work, index) => {
                                                    return(
                                                        <option key={index} 
                                                                value={ work.Id }>
                                                                    { work.Name }
                                                        </option>                                        
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>



                                <div className='gender-section field-comp'>
                                    <div className='comp-title'>
                                        Gender
                                    </div> 
                                    <div className='field-container'>
                                        <select onChange={(e) => setGender(e.target.value) } 
                                                value={ gender }
                                                name="gender" 
                                                className='input-itself'>
                                            {
                                                genders && 
                                                Array.isArray(genders) &&
                                                genders.map((gender, index) => {
                                                    return(
                                                        <option key={index} 
                                                                value={ gender.Id }>
                                                                    { gender.Name }
                                                        </option>                                        
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='job-title-section field-comp field-comp-pr'>
                                    <div className='comp-title'>
                                        Job Title (Post) <span style={{ color: 'red' }}>*</span>
                                    </div>
                                    <div className='field-container'>
                                        <input type="text"
                                                name='jobTitle'
                                                value={ jobTitle } 
                                                onChange={ _setJobTitle } 
                                                className='input-itself small'
                                                maxLength='60'
                                                placeholder='e.g Salesman, It Support ... (required)'
                                                 />       
                                    </div> 
                                </div> 

                                <div className='salary-title-section field-comp'>
                                    <div className='comp-title'>
                                        Salary
                                    </div>
                                    <div className='field-container'>
                                        <input type="text"
                                                name='salary'
                                                value={ salary } 
                                                onChange={ (e) => setSalary(e.target.value) } 
                                                className='input-itself small'
                                                maxLength='50'
                                                placeholder='Could be a range [min-max]'
                                                />
                                        {/* type='text' because value could be : 
                                            500$ | TBD | Depends on experience | 2000$-2500$
                                        <span style={{ fontSize: '12px', color: '#c1c1c1' }}>  
                                            Leave this field empty, to display the salary as 'Unspecified'.
                                        </span> */}
                                    </div> 
                                </div>



                                <div className='company-emailsubject-section field-comp'>
                                    <div className='comp-title'>
                                        Email Subject
                                    </div> 
                                    <div className='field-container'>
                                        <input type="text"
                                                name='companyemailsubject'
                                                className='input-itself small'
                                                value={ emailSubject }
                                                maxLength='100'
                    //placeholder="If empty, 'Email subject' will be replaced by 'Job Title' once a user applies for one of your jobs."
                    placeholder="The email subject you will receive once a user applies for a job (if empty, 'Job Title' will display instead)."
                                                onChange={ (e) => setEmailSubject(e.target.value) } />   
                                    </div> 
                                </div>





                                <div className='job-description-section field-comp'>
                                    <div className='comp-title'>
                                        Job Description
                                    </div> 
                                    <div className='field-container react-quill-div'>
                                        <ReactQuill 
                                            theme="snow" 
                                            name="jobDescription"
                                            value={ description } 
                                            modules={_modules}
                                            onChange={ setDescription } // worked properly
                                            placeholder="Job description and details." />
                                    </div> 
                                </div>

                                <div className='btn-save-profile field-comp' id='div-apply_2' >
                                    
                                    <input name='applyjob_2'
                                            // type='submit'
                                            type='button'
                                            onClick={ PostJob }
                                            className={`job-btn-itself ${isSending ? 'disabled' : ''}`}
                                            value={`${isSending ? 'Posting' : 'Post'}`} disabled={ isSending } />
                                    
                                    {/*                                     
                                         !isSending ?
                                         <input name='applyjob_2'
                                             type='submit' 
                                             className='job-btn-itself'
                                             value='Post' />
                                         :
                                         <div name='btn-neutral' className="disabled">
                                             Posting
                                         </div>
                                    */}
                                </div>
                            </div>
                        </div>
                </div>
            </form>
        </div>
    )
}
export default PostJob;



/*
error =  Error: ER_PARSE_ERROR: You have an error in your SQL syntax; check the manual that corresponds to your MariaDB server version for the right syntax to use near '', 'gg', 'Lebanon', '1', '1', '1', 'g', '1', '', '', '0', '', '', '', '2024-0...' at line 1
    at Sequence._packetToError (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\sequences\Sequence.js:47:14)     
    at Query.ErrorPacket (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\sequences\Query.js:79:18)
    at Protocol._parsePacket (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\Protocol.js:291:23)
    at Parser._parsePacket (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\Parser.js:433:10)
    at Parser.write (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\Parser.js:43:10)
    at Protocol.write (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\Protocol.js:38:16)
    at Socket.<anonymous> (C:\Users\user\Desktop\node_modules\mysql\lib\Connection.js:88:28)
    at Socket.<anonymous> (C:\Users\user\Desktop\node_modules\mysql\lib\Connection.js:526:10)
    at Socket.emit (node:events:519:28)
    at addChunk (node:internal/streams/readable:559:12)
    --------------------
    at Protocol._enqueue (C:\Users\user\Desktop\node_modules\mysql\lib\protocol\Protocol.js:144:48)
    at Connection.query (C:\Users\user\Desktop\node_modules\mysql\lib\Connection.js:198:25)
    at C:\Users\user\Desktop\reacttodel\server.js:227:8
    at Layer.handle [as handle_request] (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\layer.js:95:5)
    at next (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\route.js:149:13)
    at Route.dispatch (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\route.js:119:3)
    at Layer.handle [as handle_request] (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\layer.js:95:5)
    at C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\index.js:284:15
    at Function.process_params (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\index.js:346:12)      
    at next (C:\Users\user\Desktop\reacttodel\node_modules\express\lib\router\index.js:280:10) {
  code: 'ER_PARSE_ERROR',
  errno: 1064,
  errno: 1064,
  sqlMessage: "You have an error in your SQL syntax; check the manual that corresponds to your MariaDB server version for the right syntax to use near '', 'gg', 'Lebanon', '1', '1', '1', 'g', '1', '', '', '0', '', '', '', '2024-0...' at line 1",
  sqlState: '42000',
  index: 0,
  sql: "INSERT INTO `student` (`Name`, `IsConfidential`, `Email`, `Location`, `JobCategory`, `EmploymentType`, `Workplace`, `JobTitle`, `Gender`, `Salary`, `JobDescription`, `Active`, `EmailSubject`, `EmailBody`, `CompanyProfile`, `PostingDate`) VALUES ('g', 0', 'gg', 'Lebanon', '1', '1', '1', 'g', '1', '', '', '0', '', '', '', '2024-07-18 14:23:08')"    
}
inside error block
*/