
import NavBar from "../NavBar/NavBar";
import HomePage from "../../HomePage/HomePage";
import FileUploader from "../Uploader/FileUploader";
import ServerCall from "../ServerCall/ServerCall";
import NotFound from "../NoFound/NotFound";
import StudentDetails from "../ServerCall/StudentDetails";
import NewStudent from "../ServerCall/NewStudent";
import StudentEdit from "../ServerCall/StudentEdit";
import DropDownTree from "../DropDownTree/DropDownTree";
import Search from "../Search/Search";
import PostJob from "../PostJob/PostJob";
import ContactUs from "../ContactUs/ContactUs";
import LogIn from "../LogIn/LogIn";
import SignUp from "../SignUp/SignUp";
import Admin from "../Admin/Admin";
import CompanyProfile from "../CompanyProfile/CompanyProfile";
import CandidateProfile from "../CandidateProfile/CandidateProfile";
import ChangePassword from "../ChangePassword/ChangePassword";
import ApplicationSentSuccessfully from "../ApplicationSentSuccessfully/ApplicationSentSuccessfully";

import { Routes, Route } from "react-router-dom";
import { useState } from "react";
//import useFetch from "../../CustomeHooks/useFetch";
import WebSiteIcon from "../WebSiteIcon/WebSiteIcon";

import './RightSide.css';


const RightSide = () => {

    const [hideSideBar, setHideSideBar] = useState(false);


    const updatedArray = (_updatedArray) => {
        console.log("Updated Array", _updatedArray);
    }

    const toggleSidBar = () => {
        setHideSideBar(!hideSideBar);
    }

    const xx = (files) => {
        //setHideSideBar(!hideSideBar);
    }

    const [logoName, setLogoName] = useState(null);
    const [username, setUsername] = useState(null);
    const [userWant, setUserWant] = useState(false);

    const getLogo = (_logoName) => {
        //console.log("_logoName", _logoName);
        setLogoName(_logoName);
    }

    const getUsername = (_userName) => {
        setUsername(_userName);
    }

    const fn = (_bool) => {
        //console.log('right side received value');
        setUserWant(_bool);
    }
    
    return(
  
            <div className="right-side">
                {/*  NavBar should be inside the <BrowserRouter> to use the <Link to=""/> component */}
                <NavBar toggleSideBar={ toggleSidBar } flag={ hideSideBar } _logo={logoName} GoBackward={ fn }/> 

                <Routes>
                    <Route path= "/" element={ <HomePage /> } />
                    
                    {/* these routes to delete */}
                    {/* <Route path= "/upload" element={ <FileUploader XXX={xx} /> } /> */}
                    {/* <Route path= "/makeservercall" element={ <ServerCall /> } /> */}
                    {/* <Route path= "/dropdowntree" element={ <DropDownTree onChangeSelection={ updatedArray }/> } /> */}

                    <Route path= "/search/:params?" element={ <Search/> } />
                    {/* <Route path= "/student/details/:id" element={ <StudentDetails /> } /> */}
                    <Route path= "/job/details/:id" element={ <StudentDetails /> } />
                    
                    <Route path= "/company/details/:id" element={ <CompanyProfile setLogoFn={ getLogo }/> } />

                    {/* <Route path= "/candidate/details/:id" element={ <CandidateProfile setLogoFn={ getLogo }/> } /> */}

                    {/* <Route path= "/student/create" element={ <NewStudent /> } /> */}
                    {/* <Route path= "/student/edit/:id" element={ <StudentEdit /> } /> */}
                    <Route path= "/sent_successfully" element={ <ApplicationSentSuccessfully /> } />
                    

                    <Route path= "/postjob" element={ <PostJob /> } />
                    <Route path= "/contactus" element={ <ContactUs /> } />

                    <Route path= "/login" element={ <LogIn setLogoFn={ getLogo } sendUsernameFn={ getUsername } userWant={ userWant } /> } />
                    {/* <Route path= "/login" element={ <LogIn setLogoFn={ getLogo } sendUsernameFn={ getUsername } /> } /> */}
                    <Route path= "/signup" element={ <SignUp /> } />

                    <Route path="/changepassword" element={ <ChangePassword /> } />

                    <Route path="/admin" element={ <Admin /> } />
                    

                    {/* <Route path= "/websiteicon" element={ <WebSiteIcon /> } /> */}
                    

                    {/* <Route path= "/multiselect" element= { <MultiSelect /> } />
                    <Route path= "/auto" element={ <AutoCompleteInput placeholder="Choose Country"/> } /> */}
                    <Route path= "*" element={ <NotFound /> } />
                </Routes>
            </div>
  
    );
}

export default RightSide;